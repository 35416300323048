.breatherMain {
  padding: 0 16px 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.container {
  height: 116px;
  border-radius: 10px;
  background-position: center;
  background-size: cover;
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  position: relative;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.title {
  font-family: var(--font-family-special);
  font-size: 20px;
  font-weight: 600;
  line-height: 22px;
  text-align: left;
  color: #080e12;
  max-width: 190px;
  display: flex;
  align-items: center;
}

.dynamicTitle {
  max-height: 75%;
}

.darkModeTitle {
  color: #f9fafa;
}

.logo {
  /* object-fit: none; */
  margin-left: auto;
  height: 25px;
}

.button {
  padding: 4px 10px;
  border-radius: 4px;
  background-color: #080e12;
  color: #f9fafa;
  font-family: var(--font-family-special);
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  box-sizing: border-box;
  margin-top: auto;
  text-wrap: nowrap;
  width: fit-content;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 60px;
}

.buttonDarkMode {
  background-color: #f9fafa;
  color: #080e12;
}

.rightSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pictorialDisclaimerLogo {
  position: absolute;
  width: 100%;
  /* height: 100%; */
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: contain;
  border-radius: 0 0 10px 10px;
  /* height: max-content; */
}

.textDisclaimer p {
  font-family: var(--font-family-normal);
  font-size: 10px;
  font-weight: 400;
  line-height: 10px;
  text-align: left;
  color: #9ea2a4;
}
