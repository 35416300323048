@import "../../styles/typography.css";
@import "../../styles/colors.css";

.markdown-content h1,
.markdown-content h2,
.markdown-content h3,
.markdown-content h4,
.markdown-content h5,
.markdown-content h6 {
    font-family: var(--font-family-special);
}

.markdown-content a {
    text-decoration: underline;
}

.markdown-content ul {
    list-style: disc;
    margin: 0;
    padding: 0 0 0 16px;
}

.markdown-content hr {
    margin: 10px 0;
    border: 0;
    height: 1px;
    background: var(--color-chalk);
}
