.skeletonContainer {
  width: 100%;
  max-width: 1135px;
  border-radius: 8px;
}

.skeletonTitle {
  text-align: left;
  width: 250px;
  height: 20px;
  margin: 24px;
  background-color: var(--color-tarmac-light-10);
}

.skeletonBody {
  background-color: var(--color-tarmac-light-10);
  height: 300px;
  margin: 24px;
}

.container {
  /* width: 100%; */
  padding: 0 11px;
  margin-bottom: 48px;
  max-width: 1135px;
  margin-top: 16px;

  @media (--breakpoint-768) {
    padding: 0;
  }
}

.oddsTitle {
  font-family: var(--font-family-special);
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;
  padding-bottom: 16px;
}

.mainHeader {
  border: 2px solid var(--color-tarmac-light-10);
  border-radius: 8px 8px 0 0;
  padding: 8px;
  font-family: var(--font-family-special);
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0;
  text-align: center;
  background-color: var(--color-tarmac-light-10);
  text-transform: uppercase;

  @media (--breakpoint-768) {
    font-size: 32px;
    padding: 16px 0;
    line-height: 36px;
    text-align: center;
  }
}

.mainWrapper {
  background-color: var(--color-tarmac-light-10);
  padding: 4px;
  color: var(--color-chalk);
  border-radius: 0 0 8px 8px;
  margin-bottom: 16px;
}

.innerHeader {
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 8px;
  background: var(--color-tarmac);
  padding: 8px;
  border-radius: 4px;
  text-align: center;
  font-family: var(--font-family-normal);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  align-items: center;

  @media (--breakpoint-768) {
    grid-template-columns: 2fr 2.5fr 1.5fr;
    text-align: center;
    font-family: var(--font-family-special);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding: 16px;
    align-items: center;
  }
}

.rowsContainer {
  display: grid;
  grid-auto-rows: 1fr;
}

.oddsRow {
  display: grid;
  grid-template-columns: 2fr 1fr 3fr;
  gap: 4px;
  border-top: 4px solid var(--color-tarmac-light-10);
  align-items: stretchp;
  justify-content: center;

  @media (--breakpoint-768) {
    grid-template-columns: 1fr 1fr 2.5fr 1.5fr;
  }

  & .rowCell {
    text-align: center;
    background: var(--color-tarmac);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* cursor: pointer; */

    & img {
      width: 100px;
      height: 41px;

      @media (--breakpoint-768) {
        width: 160px;
        height: 64px;
      }
    }
  }

  .oddsValue {
    text-align: center;
    font-family: var(--font-family-normal);
    font-size: 14px;
    font-weight: 800;
    line-height: 15px;

    @media (--breakpoint-768) {
      font-family: var(--font-family-special);
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }
  }

  .descriptionContainer {
    color: var(--color-smoke);
    text-align: center;
    font-family: var(--font-family-normal);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 12.5px;
    background-color: var(--color-yellow);
    padding: 4px 8px;
    display: flex;
    gap: 8px;
    justify-content: center;

    & .description {
      text-align: center;
      flex: 1;
    }

    & .chevronBox {
      min-width: 24px;
      min-height: 24px;
      max-height: 24px;
      background-color: var(--color-tarmac);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;

      & svg {
        width: 16px;
        height: 16px;
        fill: var(--color-chalk);
      }
    }

    @media (--breakpoint-768) {
      color: var(--color-chalk);
      text-align: center;
      font-family: var(--font-family-special);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      background-color: var(--color-tarmac);
      justify-content: center;
    }
  }

  .buttonContainer {
    background-color: var(--color-yellow);
    color: var(--color-smoke);
    font-family: var(--font-family-special);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    display: flex;
    padding: 20px 24px 20px 32px;
    justify-content: center;
    align-items: center;
    gap: 8px;

    & img {
      width: 24px;
      height: 24px;
    }
  }
}

.chevronBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.terms {
  font-family: var(--font-family-normal);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
  padding-bottom: 16px;
}

.tradeMark {
  display: flex;
  justify-content: space-between;
}

.tradeMarkImage {
  width: auto;
  height: 14px;

  @media (--breakpoint-768) {
    height: 24px;
  }
}

.description p {
  color: var(--color-smoke);
}
