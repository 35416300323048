.countdown {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-left: 34px;
}

.value {
  font-family: var(--font-family-normal);
  color: var(--color-chalk);
  font-size: 40px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 0.4px;
  width: 64px;
}

.label {
  color: #9ea2a4;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.16px;
  font-family: var(--font-family-normal);
}

.box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

/* .box:last-child .value {
  width: unset;
} */
