.main {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
}

.notStartedText {
  font-family: var(--font-family-normal);
  font-size: 20px;
  font-weight: 700;
  line-height: 20.99px;
  color: #f9fafa;
}
