/* --font-family-normal: Oscine, AxisSTD, "Helvetica Neue", helvetica, arial, sans-serif;
    --font-family-special: DAZNTrim, AxisSTD, "Helvetica Neue", helvetica, arial, sans-serif;
    --font-family-special-condensed: DAZNTrimCondensed, AxisSTD, "Helvetica Neue", helvetica, arial, sans-serif; */

.main {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #000000;
}

.button {
  width: 325px;
  height: 40px;
  font-weight: 700;
  background-color: #f7ff1a;
  color: #080e12;
  border-radius: 4px;
  padding: 8px 16px;
  font-family: var(--font-family-special);
  text-align: center;
  font-size: 16px;
  margin-bottom: 10px;
}

.header {
  height: 61px;
  background: linear-gradient(
    273.57deg,
    rgba(247, 255, 26, 0.15) 16.78%,
    rgba(247, 255, 26, 0.44) 69.98%
  );
  display: flex;
  padding: 16px 16px 0;
  gap: 16px;
  border-radius: 24px 24px 0 0;
  border: 2px 0 0 0;
}

.betLogo {
  width: 46.59px;
  height: 24px;
}

.headerTitle {
  font-family: var(--font-family-special);
  font-size: 14px;
  font-weight: 700;
  line-height: 16.1px;
  text-align: left;
  color: #f7ff1a;
}

.eventTitle {
  font-family: var(--font-family-normal);
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
  color: #f9fafa;
}

.chevron {
  fill: #f9fafa;
  width: 25.82px;
  height: 29.82px;
  margin-left: auto;
  transition: all 0.3s ease-in-out;
}

.animateDown {
  transform: rotate(90deg);
}

.headerTitles {
  display: flex;
  flex-direction: column;
}

.body {
  background-color: #000000;
  padding: 0 16px 16px 16px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 500px;
  /* margin-right: auto;
  margin-left: auto; */

  @media screen and (max-width: 374px) {
    padding: 0 12px 12px 12px;
  }
}

.dynamicStylesBody {
  padding-top: 16px;
  padding-bottom: 0;
}

.bodyContent {
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #242d33;
}

.bodyTitle {
  color: #f9fafa;
  font-family: var(--font-family-special-condensed);
  font-size: 24px;
  font-weight: 700;
  font-style: normal;
  line-height: 84%; /* 20.16px */
  letter-spacing: 0.48px;
  text-transform: uppercase;
}

.bodyHeaderDiv {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border-bottom: 1px solid rgba(249, 250, 250, 0.4);
  width: auto;

  @media (width < 360px) {
    padding: 16px 10px 10px;
  }
}

.listOfQuestions {
  padding-bottom: 16px;
}

.list-of-questions > div:last-child > div:first-child svg {
  width: 100%;
  height: auto;
}

.listOfQuestions > div:last-child {
  border: none;
  margin: 0;
}

.activateBet {
  display: flex;
  padding: 8px;
  width: -webkit-fill-available;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: #6d7377;
  border: none;
  color: #9ea2a4;
  text-align: center;
  font-family: var(--font-family-normal);
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  margin: 16px 16px 24px;

  @media screen and (max-width: 374px) {
    margin: 12px 12px 20px;
  }
}

.dynamicActivateBet {
  margin: 16px;
}

.isButtonActive {
  background-color: #ffffff;
  color: #080e12;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin-left: 10px;
  margin-right: 10px;
  color: #9ea2a4;
}

.tac p {
  color: #9ea2a4;
  text-align: center;
  font-family: var(--font-family-normal);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
}

.tacLogos {
  height: 13px;
  width: 100%;

  @media screen and (width > 460px) {
    height: 23px;
  }
}

.nonErrorDiv {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.returnsHint {
  color: var(--Core-Colors-Primer-1000, #dddedf);
  font-family: var(--font-family-normal);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  text-align: center;
  padding-top: 16px;
}

.returnsHintMoney {
  font-weight: 700;
}

.rotate {
  animation: rotate 1s linear infinite; /* Animation name, duration, timing function, and iteration count */
}

.rotateReverse {
  animation: rotateReverse 1s linear infinite; /* Animation name, duration, timing function, and iteration count */
}

@keyframes rotate {
  from {
    transform: rotate(0deg); /* Starting angle */
  }

  to {
    transform: rotate(360deg); /* Ending angle */
  }
}

@keyframes rotateReverse {
  from {
    transform: rotate(360deg); /* Starting angle */
  }

  to {
    transform: rotate(0deg); /* Ending angle */
  }
}

button:disabled {
  cursor: not-allowed;
}

.morebetsHeading {
  color: var(--Core-Colors-Chalk-1000, var(--Core-Chalk, #f9fafa));
  text-align: center;
  font-family: var(--font-family-special);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
}

.morebetsDescription {
  color: var(--Iron, var(--Core-Iron, #ced0d2));
  text-align: center;
  font-family: var(--font-family-special);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  padding-bottom: 16px;
}

.morebetsBody {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  padding: 0 16px;
}

.morebetsClose {
  position: absolute;
  top: 0;
  right: 0;
}

.morebetsImage {
  height: 220px;
  position: relative;
  width: 100%;
}

.morebetsShadow {
  position: absolute;
  background: linear-gradient(
    180deg,
    rgba(13, 23, 29, 0) 0%,
    rgba(12, 22, 28, 0.75) 51.81%,
    #0c161c 100%
  );
  height: 127px;
  width: 100%;
  top: 93px;
}

.morebetsButton {
  width: 93%;
  margin: 0;
}

.daznBetLogoDiv {
  display: flex;
  gap: 6px;
  margin-top: 100px;
}

.loadingText {
  font-family: var(--font-family-special);
  font-size: 22px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.daznBetLogoMainDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
  margin: auto;
}

.headerTitleNew {
  font-family: var(--font-family-normal);
  font-size: 14.69px;
  font-weight: 700;
  line-height: 20.99px;
  padding-left: 3px;
  color: #f9fafa;
}

.headerNew {
  display: flex;
  align-items: center;
  gap: 9px;
  margin: 16px 16px 0 16px;
  max-width: 500px;
  padding: 8px 0;

  @media screen and (max-width: 374px) {
    margin: 12px 12px 0 12px;
  }
}

.dynamicHeader {
  margin-top: 0px;
  margin-bottom: 16px;
}

.anotherMatchTitleDiv {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: 21px;

  @media screen and (max-width: 374px) {
    padding: 0 12px;
  }
}

.leftBorder {
  border-bottom: 1px solid #3f4549;
  height: 1px;
  width: 100%;
}

.rightBorder {
  border-bottom: 1px solid #3f4549;
  height: 1px;
  width: 100%;
}

.placedBetsBody {
  background-color: #000000;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px;

  @media screen and (max-width: 374px) {
    padding: 0 12px;
  }
}

.anotherMatchTitle {
  font-family: var(--font-family-normal);
  font-size: 12px;
  font-weight: 400;
  line-height: 20.99px;
  text-align: center;
  text-wrap: nowrap;
  padding: 0 16px;
}

.moreBetsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
}

.liveTrackingDiv {
  display: flex;
  padding: 14px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #ffffff;
  backdrop-filter: blur(23.5px);
}

.liveTrackingText {
  color: #000000;
  text-align: center;
  font-family: var(--font-family-normal);
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  font-weight: 800;
}

.moreBetsinnerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.moreBets {
  color: #007aff;
  text-align: center;
  font-family: var(--font-family-normal);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.manageAccountDiv {
  background: none;
}

.manageAccountText {
  color: #f9fafa;
}

.mockCcbButton {
  text-align: center;
  margin-top: 10px;
  background-color: #f7ff1a;
  border-radius: 5px;
  padding: 8px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mockDiv {
  display: flex;
  justify-content: center;
}

.topAccordian {
  transform: rotate(180deg);
}

.questionsTitle {
  font-family: var(--font-family-normal);
  font-size: 14px;
  font-weight: 400;
  line-height: 20.99px;
  text-align: left;
  padding: 8px 16px;
  border-bottom: 1px solid #3d4549;
  min-height: 21px;

  @media screen and (max-width: 374px) {
    padding: 8px 12px;
  }
}

.dynamicQuestionsTitle {
  font-size: 12px;
}

.infoText {
  font-family: var(--font-family-normal);
  font-size: 12px;
  font-weight: 400;
  line-height: 20.99px;
  text-align: left;
}

.winningText {
  color: #ffffff;
  text-align: center;
  font-feature-settings: "calt" off;
  font-family: var(--font-family-now-g);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding: 0 20px;
}

.winningText a {
  color: #ffffff;
}

.freeBetDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 12px 16px;
  background: linear-gradient(90deg, #2d1d5c 6.37%, #5a1747 100%);
  border-radius: 16px;
}

.freeBetText {
  font-family: var(--font-family-normal);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.freeBetText span {
  color: #f7ff1a;
}

.freeBetSection {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.freeBetTermsAndConditions {
  font-family: var(--font-family-normal);
  font-size: 12px;
  font-weight: 400;
  line-height: 20.99px;
  text-align: left;
  color: #9ea2a4;
  text-decoration: underline;
}

.freeBetImage {
  object-fit: contain;
  width: 45px;
  height: 48px;
}

.manageAccountFlex {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.freeBetGif {
  width: 45px;
  height: 48px;
}

/**
 * ==============================================
 * Dot Pulse
 * ==============================================
 */
.dotsPulse {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  box-shadow: 9999px 0 0 -5px;
  animation: dotsPulse 1.5s infinite linear;
  animation-delay: 0.25s;
}
.dotsPulse::before,
.dotsPulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
}
.dotsPulse::before {
  box-shadow: 9984px 0 0 -5px;
  animation: dotsPulse-before 1.5s infinite linear;
  animation-delay: 0s;
}
.dotsPulse::after {
  box-shadow: 10014px 0 0 -5px;
  animation: dotsPulse-after 1.5s infinite linear;
  animation-delay: 0.5s;
}

@keyframes dotsPulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }
  30% {
    box-shadow: 9984px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}
@keyframes dotsPulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }
  30% {
    box-shadow: 9999px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}
@keyframes dotsPulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }
  30% {
    box-shadow: 10014px 0 0 2px;
  }
  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

.threeDotsDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.eighteenPlus p {
  font-family: var(--font-family-normal);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #6d7377;
  margin-top: 16px;
}

.dynamicEighteenPlus p {
  font-family: var(--font-family-normal);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #090e12;
  padding: 16px;
  background-color: #f9fafa;
  border-radius: 0 0px 16px 16px;
}
.dynamicEighteenPlus p a {
  font-family: var(--font-family-normal);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #090e12;
}

.fixtureDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: var(--font-family-normal);
  font-size: 14.69px;
  font-weight: 700;
  line-height: 20.99px;
  text-align: center;
}

.fixtureTitle {
  height: 30px;
  font-family: var(--font-family-normal);
  font-size: 14.69px;
  font-weight: 700;
  line-height: 20.99px;
  text-align: right;
}

.fixtureBody {
  display: flex;
  margin-bottom: 16px;
  margin-top: 3px;
  color: #ced0d2;
}

.fixtureTime {
  color: #ced0d2;
  font-family: var(--font-family-normal);
  font-size: 14.69px;
  font-weight: 700;
  line-height: 20.99px;
  text-align: center;
  padding: 0 4px;
}

.dynamicFreeBetSection {
  padding: 0 8px 12px 8px;
}
