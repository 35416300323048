.main {
  display: flex;
  /* justify-content: space-between; */
  gap: 12px;
  /* padding: 16px 16px 0px; */
}

.question {
  color: #f9fafa;
  font-family: var(--font-family-normal);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 128.571% */
  /* width: 116px; */
  /* height: 14px; */
}

.button {
  color: #f9fafa;
  text-align: center;
  font-family: var(--font-family-special);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 180% */
  border-radius: 80px;
  /* border: 1px solid  #f9fafa; */
  padding: 3px 16px;
  width: 60px;
  /* text-transform: uppercase; */
  background-color: #3d4549;
  height: 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.isBlack {
  background-color: #fbd20c;
  color: #0c161c;
}

.buttonsDiv {
  display: flex;
  width: 138px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
}

.content {
  padding: 16px;
  display: flex;
  border-bottom: 1px solid #3d4549;
  /* margin-bottom: 16px; */
  justify-content: space-between;
  flex: 1;
  align-items: center;
  gap: 16px;

  @media (width < 360px) {
    padding-bottom: 10px;
  }
  @media screen and (max-width: 374px) {
    padding: 12px;
  }
}

.borderLine {
  border-left: 2px solid rgba(249, 250, 250, 0.4);
  height: 100%;
  margin: 2px 0;
  border-radius: 8px;
}

.dotDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
