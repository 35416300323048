body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-user-select: none;
  min-height: 720px;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p a {
  font-family: var(--font-family-normal);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: #9ea2a4;
}

p,
h2,
div,
h1,
h3,
button {
  margin: 0;
  padding: 0;
  color: #ffffff;
  margin-block-start: 0;
  margin-block-end: 0;
}

a {
  cursor: default;
}
