@import "./colors.css";

:root {
  /* stylelint-disable value-keyword-case */
  /* stylelint-disable max-line-length */
  --font-family-normal: Oscine, "Helvetica Neue", helvetica, arial, sans-serif;
  --font-family-special: DAZNTrim, "Helvetica Neue", helvetica, arial,
    sans-serif;
  --font-family-special-condensed: DAZNTrimCondensed, "Helvetica Neue",
    helvetica, arial, sans-serif;
  --font-family-now-g: DAZN NOW-G, "Helvetica", sans-serif;
  /* --font-family-special: arial, sans-serif; */
  /* stylelint-enable max-line-length */
  /* stylelint-enable value-keyword-case */
  /*
        TODO: this doesnt work, we don't need to define what the size is being used for as it makes
        it so hard to work with....why do we have multiple variables with the same value??
    */
  --font-size-body-small: 12px;
  --font-size-tile-label: 14px;
  --font-size-body: 14px;
  --font-size-video-overlay: 14px;
  --font-size-menu-item: 16px;
  --font-size-navigation-item: 16px;
  --font-size-tile-title: 16px;
  --font-size-tile-metadata: 16px;
  --font-size-rail-title: 22px;
  --font-size-info-title: 24px;
  --font-size-title-medium-large: 28px;
  --font-size-title-large: 30px;
  --font-size-title-xlarge: 48px;
  --font-size-title-xxlarge: 72px;

  --font-size-8: 12px;
  --font-size-7: 14px;
  --font-size-6: 16px;
  --font-size-5: 22px;
  --font-size-4: 24px;
  --font-size-3: 28px;
  --font-size-2: 32px;
  --font-size-1: 36px;
  --font-size-0: 42px;

  /* stylelint-disable unit-whitelist */
  --line-height-1: 1.2em;
  --line-height-0: 1.4em;
  /* stylelint-enable unit-whitelist */

  --font-weight-light: 200;
  --font-weight-normal: 300;
  --font-weight-regular: 400;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;
  --font-weight-extra-bold: 800;
}

:root[lang^="ja"] {
  /* stylelint-disable value-keyword-case */
  /* stylelint-disable max-line-length */
  --font-family-normal: Oscine, AxisSTD, "Helvetica Neue", helvetica, arial,
    sans-serif;
  --font-family-special: DAZNTrim, AxisSTD, "Helvetica Neue", helvetica, arial,
    sans-serif;
  --font-family-special-condensed: DAZNTrimCondensed, AxisSTD, "Helvetica Neue",
    helvetica, arial, sans-serif;
  /* stylelint-enable value-keyword-case */
  /* stylelint-enable max-line-length */
}

.special {
  @mixin smoothing;
  color: var(--color-chalk);
  font-family: var(--font-family-special);
}

.special-bold {
  composes: special;
  font-weight: bold;
}

.normal {
  @mixin smoothing;
  color: var(--color-chalk);
  font-family: var(--font-family-normal);
}

.body {
  composes: normal;
  font-size: var(--font-size-body);
}

.body-small {
  composes: normal;
  font-size: var(--font-size-body-small);
}

.menu-item {
  composes: normal;
  color: var(--color-tarmac-light-60);
  font-size: var(--font-size-menu-item);
  font-weight: bold;
  text-align: left;
  -webkit-tap-highlight-color: var(--color-transparent-black);
}

.rail-title {
  composes: special;
  font-size: var(--font-size-rail-title);
  font-weight: 800;
  text-shadow: 0 2px 4px color(var(--color-tarmac) a(0.5));
  line-height: var(--line-height-1);
}

.tile-title {
  composes: special;
  font-weight: bold;
  text-shadow: var(--tile-text-shadow);
  font-size: var(--font-size-tile-title);
  line-height: var(--line-height-1);
}

.tile-metadata {
  @mixin smoothing;
  color: var(--color-tarmac-light-70);
  font-family: var(--font-family-normal);
  font-weight: bold;
  text-shadow: var(--tile-text-shadow);
  font-size: var(--font-size-tile-metadata);
  line-height: var(--line-height-1);
}

.tile-label {
  composes: normal;
  font-weight: 800;
  font-size: var(--font-size-tile-label);
  font-family: var(--font-family-normal);
  margin-right: 5px;
}

.footer-item-title {
  composes: normal;
  font-size: var(--font-size-navigation-item);
  font-weight: normal;
  line-height: 2.5;
}

.up-next-card-title {
  composes: special;
  font-weight: 600;
}

.info-title {
  composes: special;
  font-size: var(--font-size-info-title);
  font-weight: bold;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button-label {
  @mixin smoothing;
  font-family: var(--font-family-special);
  font-weight: 600;
  font-size: var(--font-size-6);
  text-transform: uppercase;
}

.button-label-small {
  composes: special-bold;
  font-size: var(--font-size-8);
}
