.body {
  border-radius: 14px;
}

.dynamicStylesBody {
  border: 1px solid #242d33;
}

.noOfBets {
  font-family: var(--font-family-special);
  font-size: 8px;
  font-weight: 700;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  color: #0c161c;
  background-color: #f9fafa;
}

.headerDiv {
  padding: 11px 16px;
  border-radius: 13px;
  background-color: #242d33;
  display: flex;
  justify-content: space-between;
}

.leftSection {
  display: flex;
  gap: 8px;
  align-items: center;
}

.myBetsTitle {
  font-family: var(--font-family-special);
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
}

.rightSection {
  display: flex;
  gap: 8px;
  align-items: center;
}

.toReturn {
  font-family: var(--font-family-normal);
  font-size: 14px;
  font-weight: 400;
  line-height: 20.99px;
}

.totalReturns {
  font-family: var(--font-family-normal);
  font-size: 14px;
  font-weight: 700;
  line-height: 20.99px;
  color: #f7ff1a;
}

.removeBottomBorder {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}

.statusDiv {
  background-color: #3d4549;
  border-top: 1px solid #50575a;
  border-bottom: 1px solid #50575a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7.5px 16px;

  @media screen and (max-width: 400px) {
    padding: 7.5px 10px;
  }
}

.detailsDiv {
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  padding-bottom: 14px;
  background: #0c161c;
}

.betAmount {
  font-family: var(--font-family-normal);
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
}

.leftSectionStatusDiv {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.singleQuestion {
  font-family: var(--font-family-normal);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  gap: 30px;
  height: 48px;
  align-items: center;
  padding-left: 22px;
}

.questionIcon {
  width: 12px;
  height: 12px;
  border-radius: 66.67px;
  background-color: #6d7377;
}

.svgLineTop {
  height: max-content;
  border: 1px solid #6d7377;
  flex-grow: 4;
  width: 0;
}

.svgLineBottom {
  height: max-content;
  border: 1px solid #6d7377;
  flex-grow: 4;
  width: 0;
}

.svgLineDiv {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.listOfQuestions .singleQuestion:first-child .svgLineTop {
  border: none;
}

.listOfQuestions .singleQuestion:last-child .svgLineBottom {
  border: none;
}

.returnsText {
  font-family: var(--font-family-normal);
  font-size: 14px;
  font-weight: 400;
  line-height: 20.99px;
  text-wrap: nowrap;
}

.textInsideReturns {
  font-weight: 700;
  color: #f7ff1a;
}

.freeBetText {
  font-family: var(--font-family-normal);
  font-size: 12px;
  font-weight: 400;
  line-height: 20.99px;
  color: #aaff00;
  margin-left: auto;
  margin-right: 10px;

  @media screen and (max-width: 374px) {
    margin-right: 6px;
  }
}

.wonColor {
  color: #85eba5;
}

.lostColor {
  color: #fd7777;
}

.statusText {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  color: var(--Tarmac, var(--Core-Tarmac, #0c161c));
  text-align: right;
  font-family: var(--font-family-normal);
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
}

.statusTextMaxWidth {
  @media screen and (max-width: 430px) {
    max-width: min-content;
  }
}

.liveText {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  color: var(--Tarmac, var(--Core-Tarmac, #0c161c));
  text-align: right;
  font-family: var(--font-family-normal);
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 13px;
  height: 17px;
  text-align: center;
  text-transform: uppercase;
  padding: 0 8.5px;
}
