.main {
  display: flex;
  padding: var(--Spacing-Base, 16px) var(--Spacing-Base, 16px)
    var(--Spacing-Base, 24px) var(--Spacing-Base, 16px);
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: var(--Spacing-Base, 16px);
  backdrop-filter: blur(23.5px);
  color: #f9fafa;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.14),
      rgba(255, 255, 255, 0.14)
    ),
    radial-gradient(
        29.04% 40.76% at 0% 14.02%,
        rgba(255, 255, 255, 0.136) 0%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        19.78% 53.89% at 100% 109.15%,
        rgba(255, 255, 255, 0.154) 0%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  border: 1px solid #ffffff1a;
}

.iconDiv {
  width: var(--Spacing-X-Large, 32px);
  height: var(--Spacing-X-Large, 32px);
  flex-shrink: 0;
  background-color: #50575a;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #242d33;
}

.title {
  color: var(--Core-Colors-Chalk-1000, var(--Core-Chalk, #f9fafa));
  font-family: var(--font-family-special-condensed);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 84%; /* 20.16px */
  letter-spacing: 0.48px;
  text-transform: uppercase;
}

.description {
  color: #f9fafa;
  text-align: center;
  font-family: var(--font-family-special);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  padding: 0 16px;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #0c161c;
}

.tryAgainDiv {
  display: flex;
  /* width: 325px; */
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--Core-Mako, #3d4549);
  background-color: #ffffff;
}

.tryAgainText {
  color: var(--Core-Chalk, var(--Core-Chalk, #000000));
  text-align: right;
  font-family: var(--font-family-special);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  text-transform: uppercase;
}
