/* @import "./mixins.css"; */

/* DAZN Trim */

@font-face {
  font-family: "DAZNTrim";
  font-style: normal;
  font-weight: 400;
  src: url("https://static.dazndn.com/fonts/DAZNTrim_v2.9_normal_400.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/DAZNTrim_v2.9_normal_400.woff")
      format("woff");
}

@font-face {
  font-family: "DAZNTrim";
  font-style: normal;
  font-weight: 600;
  src: url("https://static.dazndn.com/fonts/DAZNTrim_v2.9_normal_600.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/DAZNTrim_v2.9_normal_600.woff")
      format("woff");
}

@font-face {
  font-family: "DAZNTrim";
  font-style: normal;
  font-weight: 800;
  src: url("https://static.dazndn.com/fonts/DAZNTrim_v2.9_normal_800.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/DAZNTrim_v2.9_normal_800.woff")
      format("woff");
}

@font-face {
  font-family: "DAZNTrim";
  font-style: normal;
  font-weight: 700;
  src: url("https://static.dazndn.com/fonts/DAZNTrim_v2.9_normal_700.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/DAZNTrim_v2.9_normal_700.woff")
      format("woff");
}

@font-face {
  font-family: "DAZNTrim";
  font-style: italic;
  font-weight: 600;
  src: url("https://static.dazndn.com/fonts/DAZNTrim_v2.9_italic_600.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/DAZNTrim_v2.9_italic_600.woff")
      format("woff");
}

@font-face {
  font-family: "DAZNTrimCondensed";
  font-style: normal;
  font-weight: 400;
  src: url("https://static.dazndn.com/fonts/DAZNTrimCondensed_v1.0_normal_400.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/DAZNTrimCondensed_v1.0_normal_400.woff")
      format("woff");
  font-display: block;
}

@font-face {
  font-family: "DAZNTrimCondensed";
  font-style: normal;
  font-weight: 700;
  src: url("https://static.dazndn.com/fonts/DAZNTrimCondensed_v1.0_normal_700.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/DAZNTrimCondensed_v1.0_normal_700.woff")
      format("woff");
  font-display: block;
}

/* DAZN Oscine */

@font-face {
  font-family: "Oscine";
  font-style: normal;
  font-weight: 400;
  src: url("https://static.dazndn.com/fonts/Oscine_v2.001_normal_400.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/Oscine_v2.001_normal_400.woff")
      format("woff");
}

@font-face {
  font-family: "Oscine";
  font-style: normal;
  font-weight: 700;
  src: url("https://static.dazndn.com/fonts/Oscine_v2.001_normal_700.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/Oscine_v2.001_normal_700.woff")
      format("woff");
}

@font-face {
  font-family: "Oscine";
  font-style: normal;
  font-weight: 800;
  src: url("https://static.dazndn.com/fonts/Oscine_v2.001_normal_800.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/Oscine_v2.001_normal_800.woff")
      format("woff");
}

/* AxisSTD (JP characters fallback) */

@font-face {
  font-family: "AxisSTD";
  font-style: normal;
  font-weight: 300;
  src: url("https://static.dazndn.com/fonts/AxisStd_v1.111_normal_300.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/AxisStd_v1.111_normal_300.woff")
      format("woff");
}

@font-face {
  font-family: "AxisSTD";
  font-style: normal;
  font-weight: 400;
  src: url("https://static.dazndn.com/fonts/AxisStd_v1.111_normal_400.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/AxisStd_v1.111_normal_400.woff")
      format("woff");
}

@font-face {
  font-family: "AxisSTD";
  font-style: normal;
  font-weight: 600;
  src: url("https://static.dazndn.com/fonts/AxisStd_v1.111_normal_600.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/AxisStd_v1.111_normal_600.woff")
      format("woff");
}

/* Closed caption fonts */

@font-face {
  font-family: "Autourone";
  font-style: normal;
  font-weight: 400;
  src: url("https://static.dazndn.com/fonts/AutourOne_v1.002_normal_400.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/AutourOne_v1.002_normal_400.woff")
      format("woff");
}

@font-face {
  font-family: "Droid";
  font-style: normal;
  font-weight: 400;
  src: url("https://static.dazndn.com/fonts/DroidSansMono_v1.00.123_normal_400.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/DroidSansMono_v1.00.123_normal_400.woff")
      format("woff");
}

@font-face {
  font-family: "Librebaskerville";
  font-style: normal;
  font-weight: 400;
  src: url("https://static.dazndn.com/fonts/LibreBaskerville_v1.000_normal_400.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/LibreBaskerville_v1.000_normal_400.woff")
      format("woff");
}

@font-face {
  font-family: "Luxi";
  font-style: normal;
  font-weight: 400;
  src: url("https://static.dazndn.com/fonts/LuxiMono_v1.2_normal_400.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/LuxiMono_v1.2_normal_400.woff")
      format("woff");
}

@font-face {
  font-family: "Qumpellkano12";
  font-style: normal;
  font-weight: 400;
  src: url("https://static.dazndn.com/fonts/QumpellkaNo12_v00.480_normal_400.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/QumpellkaNo12_v00.480_normal_400.woff")
      format("woff");
}

/* DAZN Now */

@font-face {
  font-family: "DAZN NOW-G";
  font-style: italic;
  font-weight: 400;
  src: url("https://static.dazndn.com/fonts/DAZNNowG_v004_normal_400.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/DAZNNowG_v004_normal_400.woff")
      format("woff");
}

@font-face {
  font-family: "DAZN NOW-G";
  font-style: italic;
  font-weight: 600;
  src: url("https://static.dazndn.com/fonts/DAZNNowG_v004_normal_600.woff2")
      format("woff2"),
    url("https://static.dazndn.com/fonts/DAZNNowG_v004_normal_600.woff")
      format("woff");
}
