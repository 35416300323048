/* Brand colors can be found here: https://brand.dazn.design */
/* Only brand colors should be added here, push back on anything that isn't brand */

:root {
  /* Tarmac color variations */
  --color-tarmac: #0c161c;
  --color-tarmac-light-10: #242d33;
  --color-tarmac-light-20: #3d4549;
  --color-tarmac-light-40: #6d7377;
  --color-tarmac-light-60: #9ea2a4;
  --color-tarmac-light-70: #b6b9bb;
  --color-tarmac-light-80: #ced0d2;
  --color-black: #000000;
  --color-tarmac-transparent: #0c161c00;
  --color-smoke: #080e12;

  /* Chalk variations */
  --color-chalk: #f9fafa;
  --chalk-dark-10: #e0e1e1;
  --color-ruler: color(var(--color-chalk) a(0.2));
  --color-white: #ffffff;
  --color-dark-20: #dddedf;
  --color-dark-40: #979797;

  /* Yellow variations */
  --color-yellow: #f7ff1a;
  --color-yellow-dark-10: #dee517;
  --color-yellow-light-50: #fbff8c;

  /* Blue variations */
  --color-blue: #0070ba;
  --color-blue-dark-20: #005994;
  --color-blue-dark-30: #06b2ff;

  /* Green variations */
  --color-green: #14854d;

  /* Red variations */
  --color-danger: #fd7777;
  --color-red: #fe3938;
  --color-red-dark-10: #d12424;

  --color-skeleton: rgba(40, 40, 40, 0.5);

  /* Transparent color variations */
  --color-transparent-black: color(var(--color-tarmac) a(0));

  /* Tile colors */
  --tile-text-shadow: 0 1px 1px color(var(--color-tarmac) a(0.7));
  --tile-selected-border: inset 0 -4px 0 0 var(--color-yellow);

  /* Notification colors */
  --notification-box-shadow: color(var(--color-tarmac) a(0.8));

  /* Errors */
  --color-danger-dark-10: #bb4545;

  /* Focus states */
  --focus-chrome-primary: var(--color-blue);

  /* Terms and condition */
  --price-rise-terms-condition: #70c8ff;
}
